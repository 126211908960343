import React from 'react'
import PropTypes from 'prop-types'
import SEO from 'components/seo'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import WhatIsContentSection from 'components/whatIsComponent'
import parse from 'html-react-parser'
import CustomPageSectionComponent from 'components/customPageSectionComponent'
import CloudNativeClientsComponent from 'components/cloudNativeClientsComponent'
import SocialLinks from 'components/socialLinks'
import ContactFormButton from 'components/contactFormButton.js'
import { jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import {
  pageWrapper,
  headingColor,
  leadTextStyle,
  successListContainer,
  cloudNativeHeadingWrapper,
  clientsLogoSection
} from '../styles/cloudNativeSoftwareEngineering.module.css'
import {
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Cloud-Native Support Engineering" }) {
        edges {
          node {
            cloudNativeSoftwareEngineeringFieldGroup {
              cloudNativeSoftwareEngineeringHeader
              cloudNativeSoftwareEngineeringSubtext
              cloudNativeSoftwareEngineeringCtaText
              cloudNativeSoftwareEngineeringCtaLink
              cloudNativeWhatIsSectionImage {
                sourceUrl
              }
              cloudNativeWhatIsSectionContent {
                whatIsLeadText
                whatIsHeading
                whatIsDescription
              }
              ourApproachHeader
              ourApproachSubheader
              ourApproachDescription
              servicesWeCanHelpWithHeader
              servicesWeCanHelpWithSubheader
              servicesWeCanHelpWithDescription
              weAreTheBestFitImage {
                sourceUrl
              }
              weAreTheBestFitContent {
                bestFitLeadText
                bestFitHeading
                bestFitDescription
              }
              ourCloudNativeClientsHeader
              ourCloudNativeClientsImage {
                clientsLogo {
                  sourceUrl
                }
              } 
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const CloudNativeSupportEngineering = ({ data, pageContext }) => {
  const pageData = data.wordPress.pages.edges[0].node.cloudNativeSoftwareEngineeringFieldGroup
  const whatIsSectionImage = pageData.cloudNativeWhatIsSectionImage.sourceUrl
  const whatIsSectionContent = pageData.cloudNativeWhatIsSectionContent
  const ourApproachHeader = pageData.ourApproachHeader
  const ourApproachSubheader = pageData.ourApproachSubheader
  const ourApproachDescription = pageData.ourApproachDescription
  const servicesWeCanHelpWithHeader = pageData.servicesWeCanHelpWithHeader
  const servicesWeCanHelpWithSubheader = pageData.servicesWeCanHelpWithSubheader
  const servicesWeCanHelpWithDescription = pageData.servicesWeCanHelpWithDescription
  const weAreTheBestFitImage = pageData.weAreTheBestFitImage.sourceUrl
  const weAreTheBestFitContent = pageData.weAreTheBestFitContent
  const ourCloudNativeClientsLogo = pageData.ourCloudNativeClientsImage
  const ourCloudNativeClientsHeader = pageData.ourCloudNativeClientsHeader
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services`,
            'name': 'Professional Services',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 3
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Cloud-Native Support Engineering Information'
        title='Cloud-Native Support Engineering'
      />
      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Cloud-Native Support Engineering'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={cloudNativeHeadingWrapper}>
            <h1>{pageData.cloudNativeSoftwareEngineeringHeader}</h1>
            <p>{pageData.cloudNativeSoftwareEngineeringSubtext}</p>
            <ContactFormButton ctaText={pageData.cloudNativeSoftwareEngineeringCtaText} />
          </div>
        </div>
        <WhatIsContentSection
          backgroundColor={'var(--formFields)'}
          image={whatIsSectionImage}
          items={whatIsSectionContent}
          render={(item, index) => {
            const { whatIsLeadText, whatIsHeading, whatIsDescription } = item
            return (
              <div key={index.toString()}>
                <span className={leadTextStyle}>{whatIsLeadText}</span>
                <h2 className={headingColor}>{whatIsHeading}</h2>
                <span className={successListContainer}>{parse(whatIsDescription)}</span>
              </div>
            )
          }}
        />
        <CustomPageSectionComponent
          items={ourApproachDescription}
          subtitle={ourApproachSubheader}
          title={ourApproachHeader}
        />
        <CustomPageSectionComponent
          hasWhiteBackground
          items={servicesWeCanHelpWithDescription}
          subtitle={servicesWeCanHelpWithSubheader}
          title={servicesWeCanHelpWithHeader}
        />
        <WhatIsContentSection
          backgroundColor={'var(--textColor)'}
          image={weAreTheBestFitImage}
          items={weAreTheBestFitContent}
          render={(item, index) => {
            const { bestFitLeadText, bestFitHeading, bestFitDescription } = item
            return (
              <div key={index.toString()}>
                <span className={leadTextStyle}>{bestFitLeadText}</span>
                <h2 className={headingColor}>{bestFitHeading}</h2>
                <span className={successListContainer}>{parse(bestFitDescription)}</span>
              </div>
            )
          }}
        />
        <CloudNativeClientsComponent
          backgroundColor={'var(--blogPageBackground'}
          customWidth='640px'
          items={ourCloudNativeClientsLogo}
          render={(item, index) => {
            const { clientsLogo } = item
            return (
              <div
                className={clientsLogoSection}
                key={index.toString()}
              >
                <img src={clientsLogo.sourceUrl} />
              </div>
            )
          }}
          title={ourCloudNativeClientsHeader}
        />
        <SocialLinks
          hasWhiteBackground
          socialLinks={footerData.socialLinks} />
      </div>
    </>
  )
}

CloudNativeSupportEngineering.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default CloudNativeSupportEngineering
